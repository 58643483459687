@font-face {
    font-family: 'BagdollDisplay-Regular';
    src: url('./assets/BagdollDisplay-Regular.otf');
}

@font-face {
    font-family: 'SeptemberSpirit';
    src: url('./assets/SeptemberSpirit.ttf');
}

@font-face {
    font-family: 'BeautyGadish';
    src: url('./assets/BeautyGadish.otf');
}

@font-face {
    font-family: 'BeautyDemo';
    src: url('./assets/BeautyDemo.otf');
}

@font-face {
    font-family: 'Montserrat-BlackItalic';
    src: url('./assets/FontsFree-Net-Montserrat-BlackItalic.ttf');
}

@font-face {
    font-family: 'MYRIADPRO-REGULAR';
    src: url('./assets/FontsFree-Net-MYRIADPRO-REGULAR.ttf');
}

@font-face {
    font-family: 'GoboldBold';
    src: url('./assets/GoboldBold.otf');
}

@font-face {
    font-family: 'machiarge';
    src: url('./assets/machiarge.ttf');
}

@font-face {
    font-family: 'Montserrat-ExtraBoldItalic';
    src: url('./assets/Montserrat-ExtraBoldItalic.ttf');
}

@font-face {
    font-family: 'NuevaStd-Bold';
    src: url('./assets/NuevaStd-Bold.otf');
}

@font-face {
    font-family: 'SinkinSans-300Light';
    src: url('./assets/SinkinSans-300Light.otf');
}

@font-face {
    font-family: 'suprema-extrabold-italic';
    src: url('./assets/suprema-extrabold-italic.ttf');
}

@font-face {
    font-family: 'BanburyD';
    src: url('./assets/BanburyD.otf');
}

@font-face {
    font-family: 'Castoro-Italic';
    src: url('./assets/Castoro-Italic.ttf');
}

@font-face {
    font-family: 'Castoro-Regular';
    src: url('./assets/Castoro-Regular.ttf');
}

@font-face {
    font-family: 'Dmitrii-Chirkov-Moon-Time-Swashes';
    src: url('./assets/Dmitrii-Chirkov-Moon-Time-Swashes.otf');
}

@font-face {
    font-family: 'EduTASBeginner-VariableFont_wght';
    src: url('./assets/EduTASBeginner-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'LibreBaskerville-Bold';
    src: url('./assets/LibreBaskerville-Bold.ttf');
}

@font-face {
    font-family: 'LibreBaskerville-Italic';
    src: url('./assets/LibreBaskerville-Italic.ttf');
}

@font-face {
    font-family: 'LibreBaskerville-Regular';
    src: url('./assets/LibreBaskerville-Regular.ttf');
}

@font-face {
    font-family: 'LondonBetween';
    src: url('./assets/LondonBetween.ttf');
}

@font-face {
    font-family: 'LondonMM';
    src: url('./assets/LondonMM.ttf');
}

@font-face {
    font-family: 'LondonTwo';
    src: url('./assets/LondonTwo.ttf');
}

@font-face {
    font-family: 'MoonTime-Regular-1';
    src: url('./assets/MoonTime-Regular-1.otf');
}

@font-face {
    font-family: 'MoonTime-Regular-2';
    src: url('./assets/MoonTime-Regular-2.ttf');
}

@font-face {
    font-family: 'Skia-Regular';
    src: url('./assets/Skia-Regular.ttf');
}

@font-face {
    font-family: 'OoohBaby-Regular';
    src: url('./assets/OoohBaby-Regular.ttf');
}

@font-face {
    font-family: 'BalsamiqSans-Bold';
    src: url('./assets/BalsamiqSans-Bold.ttf');
}

@font-face {
    font-family: 'BalsamiqSans-Regular';
    src: url('./assets/BalsamiqSans-Regular.ttf');
}

@font-face {
    font-family: 'Triumph-Wheels-Rough';
    src: url('./assets/Triumph-Wheels-Rough.otf');
}

@font-face {
    font-family: 'BLUE-OCEAN';
    src: url('./assets/BLUE-OCEAN.ttf');
}

@font-face {
    font-family: 'Fisherman-Regular';
    src: url('./assets/Fisherman-Regular.otf');
}

@font-face {
    font-family: 'Super-Festival-Personal-Use';
    src: url('./assets/Super-Festival-Personal-Use.ttf');
}
:root {
    --primary-color: black;
}

body {
    background-color: var(--primary-color);
}
.css-1jrmlnh {
    background-image: none;
    /* background-color: aqua; */
    /* set color from varibales */
    background-color: var(--primary-color);
}



.calendar {
    width: 900px;
    height: 600px;
    display: flex;
    flex-direction: column;
  }
  
  .calendar-header {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
  }
  
  .calendar-body {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .table-header {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  .weekday {
    width: 100px;
    text-align: center;
  }
  
  .table-content {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;
  }
  
  .calendar-day {
    width: 125px;
    height: 75px;
    position: relative;
    border: 1px solid #a6a6a6;
  }
  
  .calendar-day:hover {
    background-color: rgba(0,0,0,.10);
  }
  
  .calendar-day p {
    position: absolute;
    right: 10px;
    color: #a6a6a6;
  }
  
  .current p {
    color: #000000;
  }
  
  .selected p {
    color: #cc0000;
    font-weight: bold;
  }